import React from 'react';
import profilePhoto from '../../assets/images/poster/TripDescriptionPage/ProfilePhoto/AshwinUnni.png';

function ProfileDetailsTripDescription(props) {
    return (
        <div className={props.className}>
            <img src={profilePhoto} className='profile-photo' alt='...' />
            <div className='row-center pt-2'>
                <span className='trip-description-profile-title'>Trip Master</span>
            </div>
            <div className='row-center pt-2'>
                <span className='trip-description-profile-name'>Ashwin Unni</span>
            </div>
        </div>



    );
}

export default ProfileDetailsTripDescription;
