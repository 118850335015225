import React from 'react'

function RoundImageTextCard(props) {
    return (
        <div class="round-container mt-4" style={{ backgroundColor: props.bgColor || 'lightgray' }}>
            <div class="round-container-half ">
                <img src={props.imgSrc} alt='treeWaterImg' />
            </div>
            <div class="round-container-half ">
                <p className='round-container-title'>Lorem Ipsum is</p>
                <p className='round-container-text'>Contrary to popular belief, Lorem Ipsum.</p>
            </div>
            <div class="round-container-circle-button hover-effect-blue" style={{ backgroundColor: props.btnColor || 'blue' }}>
                <i class="fa-solid fa-arrow-right" ></i>
            </div>
        </div >
    )
}

export default RoundImageTextCard