import React from 'react'
import TopNavBar from '../../components/navbars/topNavBar';
import FooterBar from '../../components/navbars/footerBar';
import AshwinPhoto from '../../assets/images/poster/TripPage/profilePhoto/Ashwin.png'
import IncrementDecrement from '../../components/IncrementDecrement/incrementDecrement';
import ContinueButton from '../../components/Buttons/Continue/continueButton';
import TripPageCarousel from '../../components/Carousel/tripPageCarousel';
import GoaMap from '../../assets/images/poster/TripPage/Goa/GoaMap.png'

import GoaCircle1 from '../../assets/images/poster/TripPage/Goa/GoaCircle1.png'
import GoaCircle2 from '../../assets/images/poster/TripPage/Goa/GoaCircle2.png'
import GoaCircle3 from '../../assets/images/poster/TripPage/Goa/GoaCircle3.png'
import GoaCircle4 from '../../assets/images/poster/TripPage/Goa/GoaCircle4.png'

function TripPage(props) {

    return (
        <>
            <TopNavBar />

            <div className='in-poster-align'>
                <div className='goa-image-background'>
                    <div className='container-fluid h-100'>
                        <div className='row h-100 align-items-center'>
                            <div className='col-12 text-center'>
                                <span className='trip-poster-heading'>Together With Your<br />Tribe in Goa</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row py-5'>
                    <div className='col-6'>
                        <div className=''>
                            <span className='package-heading-trip-page-black'>Package Name:<br />Goa Bliss Getaway</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>14 Jan, 2024 - 17 Jan,2024</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>4 days and 1 night stay.</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>Daily buffet breakfast included.</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>Round-trip economy class flights</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>Full-day guided tour.</span>
                        </div>
                        <div className=''>
                            <span className='package-desc-trip-page-black'>24/7 customer support during the trip.</span>
                        </div>
                    </div>
                    <div className='col-6 row-right'>
                        <div className=''>
                            <div className='row-center'>
                                <img src={AshwinPhoto} className='profile-photo-trip-page' alt='...' />
                            </div>
                            <div className='row-center'>
                                <span className='profile-photo-tag-trip-page'>Trip Master <br /></span>
                            </div>
                            <div className='row-center'>
                                <span className='profile-photo-name-trip-page'>Ashwin Unni</span>
                            </div>
                        </div>
                    </div>
                </div>






                <div className=" row align-content-center py-5">
                    <div className="col-lg-12">
                        <div className="card-trip">
                            <div className='row py-3'>
                                <div className='col-6 row-center'>
                                    <span className='price-card-trip-page'><span className='rs-sign-trip-page'>₹</span> 10,000/-</span>
                                </div>
                                <div className='col-6 row-center'>
                                    <IncrementDecrement />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 row-center'>
                                    <ContinueButton
                                        name="Invite my Tribe"
                                        className="invite-to-tribe-btn"
                                    />
                                </div>
                                <div className='col-6 row-center'>
                                    <ContinueButton
                                        name="Book Now"
                                        className="book-now-btn"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='py-5'>
                    <TripPageCarousel />
                    <div className='row row-center py-5'>
                        <span className='trip-carousel-description'>
                            Welcome to Goa, where sun-soaked beaches, vibrant culture, and a laid-back atmosphere create the perfect escape. Nestled
                            <br />
                            on the west coast of India, Goa is renowned for its stunning coastline, historical charm, and lively energy.
                        </span>
                    </div>
                </div>


                <div className='container'>
                    <span className='heading-4-home-black row-center'>Our Accommodations</span>

                    CARDS CARDS CARDS CARDS
                </div>


                <div className='container'>
                    <div className='pt-5'>
                        <span className='heading-4-home-black row-center'>Venue</span>
                    </div>
                    <div className='py-5'>
                        <img src={GoaMap} alt='...' />
                    </div>
                </div>


                <div className='container'>
                    <span className='heading-4-home-black row-center py-5'>Trip Itinerary</span>

                    <div className='row pb-3'>
                        <div className='col-3 row-center'>
                            <img className='trip-itinerary-circle-image' src={GoaCircle1} alt='...' />
                        </div>
                        <div className='col-3 row-center'>
                            <img className='trip-itinerary-circle-image' src={GoaCircle2} alt='...' />
                        </div>
                        <div className='col-3 row-center'>
                            <img className='trip-itinerary-circle-image' src={GoaCircle3} alt='...' />
                        </div>
                        <div className='col-3 row-center'>
                            <img className='trip-itinerary-circle-image' src={GoaCircle4} alt='...' />
                        </div>
                    </div>


                    <div className='row pb-5'>
                        <div className='col-3 row-center'>
                            <span className='trip-itinerary-circle-image-day'>Day 1</span>
                        </div>
                        <div className='col-3 row-center'>
                            <span className='trip-itinerary-circle-image-day'>Day 2</span>
                        </div>
                        <div className='col-3 row-center'>
                            <span className='trip-itinerary-circle-image-day'>Day 3</span>
                        </div>
                        <div className='col-3 row-center'>
                            <span className='trip-itinerary-circle-image-day'>Day 4</span>
                        </div>
                    </div>





                </div>

            </div>

            <FooterBar />
        </>
    )
}

export default TripPage;