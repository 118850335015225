import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Goa1 from '../../assets/images/poster/TripDescriptionPage/Goa/GoaCarousel/Goa1.png'
import Goa2 from '../../assets/images/poster/TripDescriptionPage/Goa/GoaCarousel/Goa2.png'
import Goa3 from '../../assets/images/poster/TripDescriptionPage/Goa/GoaCarousel/Goa3.png'
import leftArrow from "../../assets/images/poster/TripDescriptionPage/Goa/GoaCarousel/arrowLeft.svg"
import rightArrow from "../../assets/images/poster/TripDescriptionPage/Goa/GoaCarousel/arrowRight.svg"

function TripDescriptionPagePhotoCarousel(props) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: (current, next) => setCurrentSlide(next)
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    return (
        <div className="slider-container">

            <div className="row">
                <div className="col-1">
                    <span className="trip-page-carousel-left-arrow">
                        <img src={leftArrow} onClick={goToPrevSlide} alt="..." />
                    </span>
                </div>
                <div className="col-10">

                    <Slider ref={sliderRef} {...settings}>
                        <div>
                            <img src={Goa1} alt='...' />
                        </div>
                        <div>
                            <img src={Goa2} alt='...' />
                        </div>
                        <div>
                            <img src={Goa3} alt='...' />
                        </div>
                        <div>
                            <img src={Goa1} alt='...' />
                        </div>
                        <div>
                            <img src={Goa2} alt='...' />
                        </div>
                        <div>
                            <img src={Goa3} alt='...' />
                        </div>
                    </Slider>
                </div>
                <div className="col-1">
                    <span className="trip-page-carousel-right-arrow">
                        <img src={rightArrow} onClick={goToNextSlide} alt="..." />
                    </span>
                </div>
            </div>

        </div>
    );
}

export default TripDescriptionPagePhotoCarousel;
