import React from 'react'

function BtnCircleSearch() {

    return (
        <>
            <span className="circle-search hover-effect-blue">
                <i class="fa-solid fa-magnifying-glass"></i>
            </span>
        </>
    )
}

export default BtnCircleSearch;