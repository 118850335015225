import React from 'react';
import BtnViewDetail from '../../Buttons/viewDetail/viewDetail';
function TravelCardVertical(props) {
    return (
        <>
            <div class="mb-4 card-travel-vertical">
                <div className='d-flex'>
                    <img src={props.background} class="card-travel-vertical-img travel-card-image-hover" alt="..." />
                    <div class="card-body ms-5">
                        <span className="card-title">
                            <div className="row pb-1">
                                <div className="col-8">
                                    <span className='card-horizontal-plan'>
                                        {props.plan}
                                    </span>
                                </div>
                                <div className="col-3 row-center">
                                    <span className='horizontal-card-rating'>
                                        ⭐ {props.rating}
                                    </span>
                                </div>
                            </div>
                        </span>
                        <div className="row pb-2">
                            {/* <div className="col-1"></div> */}
                            <div className="col-10">
                                <span className="card-text card-horizontal-plan-type">
                                    {props.planType}
                                </span>
                            </div>
                        </div>
                        <span className="card-text">
                            <div className="row pb-3">
                                {/* <div className="col-1"></div> */}
                                <div className="col-10">
                                    <span className='card-horizontal-seat-left-numbers'>
                                        {props.seatLeft}
                                    </span>
                                    <span className='card-horizontal-plan-type ps-3'>
                                        Seat Left
                                    </span>
                                </div>
                            </div>
                        </span>
                        <div className="row profile-row-horizontal-card">
                            {/* <div className="col-1"></div> */}
                            <div className="col-1 ps-2 me-3">
                                <img src={props.plannerPhoto} className="image--cover" alt="..." />
                            </div>
                            <div className="col-4 ps-4 p-0 profile-name-col-horizontal-card">
                                <div className="row ps-4 p-0">
                                    <span className='px-0 card-horizontal-name'>
                                        {props.plannerName}
                                    </span>
                                    <span className="px-0 card-horizontal-master">
                                        {props.plannerProfile}
                                    </span>
                                </div>
                            </div>
                            <div className="col-5 ps-0 pe-0 profile-btn-col-horizontal-card">
                                <BtnViewDetail
                                className="row-center"
                                    name="View Details"
                                />
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    );
}

export default TravelCardVertical;
