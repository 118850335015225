import React from 'react';
function CommunityProfileSmallCard(props) {
    return (
        <>
            <span className={`username-container ${props.className} community-small-card community-small-profile-card-image-hover`}>
                {/* <span className="{props.className}community-small-card"> */}
                <div className="community-profile">
                    <div className="community-profile-img">
                        <img src={props.smallImg} alt="..." />
                    </div>
                    <div className="community-profile-description">
                        <span className="trip-title-community px-3">{props.tripTitle}</span>
                        <span className="trips-community px-3">{props.totalTrips}</span>
                    </div>
                </div>
            </span>
        </>
    );
}

export default CommunityProfileSmallCard;
