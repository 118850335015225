import React from 'react'

function BtnLeftArrow(props) {

    return (
        <>
            <span className={`username-container ${props.className} BtnRightArrow hover-effect-blue`} onClick={props.onClick}>
                <i class="fa-solid fa-arrow-left"></i>
            </span>
        </>
    )
}

export default BtnLeftArrow;

