import React from 'react';
import AyushmanPhoto from '../../assets/images/influencerPhoto/Ayushman.png';
import BtnLeftArrow from '../Buttons/arrow/leftArrow';
import BtnRightArrow from '../Buttons/arrow/rightArrow';


const InfluencerCarouselMobile = () => {


    return (
        <div className="container mt-5 py-5 d-block d-sm-none">
            <div className="row justify-content-center">
                <div className="col-12">
                    <div id="carouselExampleIndicators" class="carousel slide">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner influencer-carousel-mobile">
                            <div class="carousel-item active">
                                <img src={AyushmanPhoto} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={AyushmanPhoto} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={AyushmanPhoto} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={AyushmanPhoto} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={AyushmanPhoto} class="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                    <div className='d-flex justify-content-center pt-5'>
                        <BtnLeftArrow
                            className="me-2"
                        />
                        <BtnRightArrow
                            className="ms-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfluencerCarouselMobile;