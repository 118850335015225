import React from 'react';
import footerSectionImg from '../../assets/images/backgound/footer-section.png';



function FooterBar(props) {
  return (
    <>

      <div className='contact-wrapper join-community-section'>
        {!props.display &&
          <div className="container">
            <div className=" row align-content-center ">
              <div className="col-lg-12">
                <div className="contact">
                  <p className="contact-title">
                    Join The tWYT Community
                  </p>
                  <p className='contact-sub-title'>
                    Get Early Bird Deals, Extra Discounts & Priority Customer Support.
                  </p>
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-4 py-3">
                      <input type="txt" className="form-control contact-form-control" id="name" placeholder="Name"></input>
                    </div>
                    <div className="col-md-4  py-3">
                      <input type="email" className="form-control contact-form-control" id="emailId" placeholder="name@example.com"></input>
                    </div>
                    <div className="col-md-3  py-3">
                      <button type="button" className="btn btn-light w-100 contact-subscribe-btn hover-effect-white">subscribe now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <footer className={`footer pb-5 ${!props.display ? '' : 'pt-5'}`}>
        <div className="container px-4">
          <div className="row">
            <div className="col-lg-5 mb-3">
              <h3>Wir halten dich auf dem laufenden</h3>
              <h4>Deine E-Mail Adresse</h4>
              <hr />
            </div>
            <div className="col-6 col-lg-2 offset-lg-1 mb-3">
              <span className="footer-header">Quick links</span>
              <ul className="list-unstyled footer-list-unstyled  mt-3">
                <li className="mb-2"><a href="/" className='hover-foot-item'>So gehts</a></li>
                <li className="mb-2"><a href="/" className='hover-foot-item'>Erfahrung</a></li>
                <li className="mb-2"><a href="/" className='hover-foot-item'>Aligner</a></li>
                <li className="mb-2"><a href="/" className='hover-foot-item'>Preise</a></li>
                <li className="mb-2"><a href="/" className='hover-foot-item'>Standorte</a></li>
              </ul>
            </div>
            <div className="col-6 col-lg-2 mb-3">
              <span className="footer-header">Newz</span>
              <ul className="list-unstyled footer-list-unstyled mt-3">
                <li className="mb-2"><a className='hover-foot-item' href="/">Blog</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">FAQ</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">Lift Media</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">Offene Stellen</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">Presse kit</a></li>
              </ul>
            </div>
            <div className="col-6 col-lg-2 mb-3">
              <span className="footer-header mt-0">Behandlung</span>
              <ul className="list-unstyled footer-list-unstyled mt-3">
                <li className="mb-2"><a className='hover-foot-item' href="/">Gratis Termin</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">Freunde einladen</a></li>
                <li className="mb-2"><a className='hover-foot-item' href="/">Patienteninformationen</a></li>
              </ul>
            </div>
          </div>


          <div className="row justify-content-between">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-6 py-3">© 2020 Lift Media.All right reserved</div>
            <div className="col-sm-12 col-md-auto py-3">
              <ul className="list-inline">
                <li className="list-inline-item"><a href="/" className="footer-link hover-foot-item">Dateenschutz</a></li>
                <li className="list-inline-item"><a href="/" className="footer-link hover-foot-item">Impressum</a></li>
                <li className="list-inline-item"><a href="/" className="footer-link hover-foot-item">Cookie Policy</a></li>
                <li className="list-inline-item"><a href="/" className="footer-link hover-foot-item">AGBs</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-img w-100 ' style={{
          backgroundImage: `url("${footerSectionImg}")`,
          backgroundRepeat: 'repeat-x',
          width: '100%',
          height: '100px'
        }}>
        </div>
      </footer>
    </>
  )
}

export default FooterBar