import React from 'react';

function FindExperiences(props) {
    return (
        <>
            <button type="button" className={`${props.className} btn-findExperiences hover-effect-blue`}  onClick={props.onClick}>
                {props.name}
            </button>
        </>
    );
}

export default FindExperiences;
