import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AyushmanPhoto from '../../assets/images/influencerPhoto/Ayushman.png';
import BtnLeftArrow from '../Buttons/arrow/leftArrow';
import BtnRightArrow from '../Buttons/arrow/rightArrow';
import one from '../../assets/images/influencerPhoto/1.png';
import two from '../../assets/images/influencerPhoto/2.png';
import four from '../../assets/images/influencerPhoto/4.png';
import five from '../../assets/images/influencerPhoto/5.png';


const InfluencerCarousel = () => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(2);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        initialSlide: 1,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        },
    };

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const [images, setImages] = useState(null);

    useEffect(() => {
        console.log('sliderRef ', sliderRef);
        const generateJSX = (offset) => {
            let positions = ['position-1', 'position-2', 'position-3', 'position-4', 'position-5'];
            let s1 = [five, one, two, AyushmanPhoto, four];
            let s2 = [one, two, AyushmanPhoto, four, five];
            let s3 = [two, AyushmanPhoto, four, five, one];
            let s4 = [AyushmanPhoto, four, five, one, two];
            // let s1 = [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto];
            // let s2 = [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto];
            // let s3 = [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto];
            // let s4 = [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto];


            let s = {
                0: [four, five, one, two, AyushmanPhoto],
                1: [five, one, two, AyushmanPhoto, four],
                2: [one, two, AyushmanPhoto, four, five],
                3: [two, AyushmanPhoto, four, five, one],
                4: [AyushmanPhoto, four, five, one, two]
                // 0: [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto],
                // 1: [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto],
                // 2: [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto],
                // 3: [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto],
                // 4: [AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto, AyushmanPhoto]
            }

            return s[currentSlide].map((image, index) => {
                const positionClass = positions[index];
                return (
                    <div key={index}>
                        <img
                            src={image}
                            className={`influencer-photo ${index === currentSlide ? 'current-slide' : ''} ${positionClass}`}
                            alt="..."
                        />
                    </div>
                );
            });


        };
        console.log('currentSlide ', currentSlide);
        setImages(generateJSX(currentSlide - 1));


    }, [currentSlide]);


    return (
        <div className="container mt-5 py-5">
            <div className="row justify-content-center">
                <div className="col-6">
                </div>
                <div className="col-12">
                    <Slider {...settings} ref={sliderRef}>
                        {images}
                    </Slider>
                    <div className='d-flex justify-content-center pt-5'>
                        <BtnLeftArrow
                            className="me-2"
                            onClick={goToPrevSlide}
                        />
                        <BtnRightArrow
                            className="ms-2"
                            onClick={goToNextSlide}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfluencerCarousel;
