import React from 'react';
import achivment1 from '../../../../assets/images/TitleLogo/fiftyPlusTogetherWithTribe.png'
import ButtonReadMore from '../../../Buttons/ReadMore/ButtonReadMore';
import experiance1 from '../../../../assets/images/clipart/LoginModal/FourthModal/1.svg'
import TravelCardCarouselHorizontal from '../../../Carousel/travelCardCarouselHorizontal';
import { useNavigate } from 'react-router-dom';

function TravelCardMultiImageHorizontal(props) {

    const navigate = useNavigate();

    const onTripPage = (e) => {
        e.preventDefault();
        navigate('/trip-description-page');
      }
    return (
        <>

            <div class="card travel-card-hori-multi" style={{ width: '18rem' }}>

                <span className={`username-container ${props.className} trip-card-hori-multi-profile-section pb-3`}>
                    <div className="profile-pic-trip-card-hori-multi">
                        <div className="col-3 pe-2">
                            <img src={props.profilePic} alt="..." />
                        </div>
                        <div className="col-6 pe-2">
                            <span className="trip-card-hori-multi-profile-name">{props.profileName}</span>
                            <span className="trip-card-hori-multi-profile-title"><br />{props.profileTitle}</span>
                        </div>
                        <div className='col-3 ps-2'>
                            <img src={achivment1} alt="..." />
                        </div>
                    </div>
                </span>


                <span>
                    <div className='col'>
                        <div className='row'>
                            <span className='travel-card-multi-city-specility  pb-2'>Through the dunes of Rajasthan</span>
                        </div>
                        <div className='row'>
                            <span className='travel-card-multi-city-tour-days  pb-2'>9N 10D <span>.</span> <span className='travel-card-multi-city-location-type'>Multiple Locations</span></span>
                        </div>
                    </div>
                </span>

                <TravelCardCarouselHorizontal />

                <div class="travel-card-hori-multi-body">
                    <spam className="travel-card-hori-multi-experiance-type">EXPERIENCE SPECIFIC</spam>

                    <div className='mt-3'>
                        <button class="experiance-title me-3 px-3" type="button">
                            <span class="experiance-logo"><img src={experiance1} alt="..." /></span>
                            <span class="experiance-button-text ps-2">Hiking</span>
                        </button>

                        <button class="experiance-title px-3" type="button">
                            <span class="experiance-logo"><img src={experiance1} alt="..." /></span>
                            <span class="experiance-button-text ps-2">Rafting</span>
                        </button>
                    </div>
                    <div className='mt-3'>
                        <button class="experiance-title me-3 px-3" type="button">
                            <span class="experiance-logo"><img src={experiance1} alt="..." /></span>
                            <span class="experiance-button-text ps-2">Star Gazing</span>
                        </button>

                        <button class="experiance-title px-3" type="button">
                            <span class="experiance-logo"><img src={experiance1} alt="..." /></span>
                            <span class="experiance-button-text ps-2">Rafting</span>
                        </button>
                    </div>

                    <div>

                        <ButtonReadMore
                            className="w-100 btn-learnMore mt-3"
                            name="Learn More"
                            onClick={onTripPage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TravelCardMultiImageHorizontal;
