import React from 'react';

function PassionateButton(props) {
    return (
        <>
            <button type="button" className='btn-passionate p-4' onClick={props.onClick}>
                <div className='d-flex'>
                    <span className='ps-4'>
                        <img src={props.btnCoverImg} class="passionate-image-cover" alt=".." />
                    </span>
                    <span className='ps-4 btn-passionate-text'>
                        {props.name}
                    </span>
                </div>
            </button>
        </>
    );
}

export default PassionateButton;
