import React from 'react'
import TopNavBar from '../../components/navbars/topNavBar';

function Login(props) {

    return (
        <>
            <TopNavBar />
            <div>

                <div className=''>
                    <div className='login-left-section'>
                        <div className='col-6 left-image-section-login'>

                        </div>
                    </div>
                    <div className='col-6'>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;