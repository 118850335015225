import React, { useEffect } from 'react';
import one from "../../assets/images/backgound/PassionateAboutTravel/1.png"
import two from "../../assets/images/backgound/PassionateAboutTravel/2.png"
import three from "../../assets/images/backgound/PassionateAboutTravel/3.png"
import four from "../../assets/images/backgound/PassionateAboutTravel/4.png"
import five from "../../assets/images/backgound/PassionateAboutTravel/5.png"
import six from "../../assets/images/backgound/PassionateAboutTravel/6.png"

const PassionateAboutTravel = () => {
  useEffect(() => {
    const columns = document.querySelectorAll('.single-column');

    columns.forEach((column) => {
      column.style.setProperty('--animation', 'slide');
      // column.style.setProperty('height', '200%');
      column.innerHTML = column.innerHTML + column.innerHTML;
    });
  }, []);

  return (
    <div className="passionate-vertical-slider">
      <div className="single-column pe-3">
        <a href="/">
          <img src={one} alt="..." />
        </a>
        <a href="/">
          <img src={two} alt="..." />
        </a>
        <a href="/">
          <img src={three} alt="..." />
        </a>
      </div>
      <div className="single-column ps-3">
        <a href="/">
          <img src={four} alt="..." />
        </a>
        <a href="/">
          <img src={five} alt="..." />
        </a>
        <a href="/">
          <img src={six} alt="..." />
        </a>
      </div>
    </div>
  );
};

export default PassionateAboutTravel;
