// import React, { useRef, useState } from "react";
// import Slider from "react-slick";
// import Goa1 from '../../assets/images/poster/TripPage/Goa/Goa1.png'
// import Goa2 from '../../assets/images/poster/TripPage/Goa/Goa2.png'
// import Goa3 from '../../assets/images/poster/TripPage/Goa/Goa3.png'
// import Goa4 from '../../assets/images/poster/TripPage/Goa/Goa4.png'
// import BtnLeftArrow from "../Buttons/arrow/leftArrow";
// import BtnRightArrow from "../Buttons/arrow/rightArrow";

// function TripPageCarousel(props) {
//     const [currentSlide, setCurrentSlide] = useState(0);
//     const sliderRef = useRef(null);

//     var settings = {
//         dots: true,
//         infinite: true,
//         arrows: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         initialSlide: 0,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     initialSlide: 2
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 }
//             }
//         ],
//         beforeChange: (current, next) => setCurrentSlide(next)
//     };

//     const goToNextSlide = () => {
//         if (sliderRef.current) {
//             sliderRef.current.slickNext();
//         }
//     };

//     const goToPrevSlide = () => {
//         if (sliderRef.current) {
//             sliderRef.current.slickPrev();
//         }
//     };

//     return (
//         <div className="slider-container">
//             <Slider ref={sliderRef} {...settings}>
//                 <div>
//                     <img src={Goa1} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa2} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa3} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa4} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa1} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa2} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa3} alt='...' />
//                 </div>
//                 <div>
//                     <img src={Goa4} alt='...' />
//                 </div>

//             </Slider>
//             <BtnLeftArrow
//                 onClick={goToPrevSlide}

//             />
//             <BtnRightArrow
//                 onClick={goToNextSlide}
//             />
//         </div>
//     );
// }

// export default TripPageCarousel;
























































import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Goa1 from '../../assets/images/poster/TripPage/Goa/Goa1.png'
import Goa2 from '../../assets/images/poster/TripPage/Goa/Goa2.png'
import Goa3 from '../../assets/images/poster/TripPage/Goa/Goa3.png'
import Goa4 from '../../assets/images/poster/TripPage/Goa/Goa4.png'
import BtnLeftArrow from "../Buttons/arrow/leftArrow";
import BtnRightArrow from "../Buttons/arrow/rightArrow";

function TripPageCarousel(props) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: (current, next) => setCurrentSlide(next)
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    return (
        <div className="slider-container">

            <div className="row">
                <div className="col-1">
                    <BtnLeftArrow
                        onClick={goToPrevSlide}
                        className="trip-page-carousel-left-arrow"
                    />
                </div>
                <div className="col-10">

                    <Slider ref={sliderRef} {...settings}>
                        <div>
                            <img src={Goa1} alt='...' />
                        </div>
                        <div>
                            <img src={Goa2} alt='...' />
                        </div>
                        <div>
                            <img src={Goa3} alt='...' />
                        </div>
                        <div>
                            <img src={Goa4} alt='...' />
                        </div>
                        <div>
                            <img src={Goa1} alt='...' />
                        </div>
                        <div>
                            <img src={Goa2} alt='...' />
                        </div>
                        <div>
                            <img src={Goa3} alt='...' />
                        </div>
                        <div>
                            <img src={Goa4} alt='...' />
                        </div>
                    </Slider>
                </div>
                <div className="col-1">
                    <BtnRightArrow
                        onClick={goToNextSlide}
                        className="trip-page-carousel-right-arrow"
                    />
                </div>
            </div>

        </div>
    );
}

export default TripPageCarousel;
