import React from 'react';

function ShortText(props) {


    return (
        <>


            <input
                type="text"
                className={`form-control ${props.ShortTextClasses ? props.ShortTextClasses : ""} `}
                id="exampleFormControlInput1"
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
            />
        </>
    );
}

export default ShortText;