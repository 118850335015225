import React from 'react';

function BtnViewDetail(props) {
    return (
        <>
            <button type="button" className={`${props.className} btn-viewDetail px-3 hover-effect-blue`}
>
                {props.name}
            </button>
        </>
    );
}

export default BtnViewDetail;
