import React from 'react'
import TopNavBar from '../../components/navbars/topNavBar';
import FooterBar from '../../components/navbars/footerBar';
import TripDescriptionPagePhotoCarousel from '../../components/Carousel/tripDescriptionPagePhotoCarousel';
import ProfileDetailsTripDescription from '../../components/ProfileCardTripDescription/profileDetailsTripDescription';
import IncrementDecrement from '../../components/IncrementDecrement/incrementDecrement';
import ContinueButton from '../../components/Buttons/Continue/continueButton';
import GoaVenue from '../../assets/images/poster/TripDescriptionPage/Goa/GoaVenue.png'
import ratingStar from '../../assets/images/clipart/rating-star.svg'
import ReviewCard from '../../components/cards/ReviewCard/reviewCard';


import valueRating from '../../assets/images/clipart/value-rating.svg'
import mealsRating from '../../assets/images/clipart/meals-rating.svg'
import locationRating from '../../assets/images/clipart/location-rating.svg'
import accomodationRating from '../../assets/images/clipart/accomodation-rating.svg'
import checkInRating from '../../assets/images/clipart/check-in-rating.svg'
function TripDescriptionPage(props) {

    return (
        <>
            <TopNavBar />

            <div className='in-poster-align'>
                <div className='trip-description-page-image-background'>

                </div>
            </div>


            <div className='container'>
                <div className='row'>
                    <div className='col-6 pe-5'>
                        <div className='pt-5'>
                            <span className='trip-description-page-heading-1'>Goa</span>
                        </div>

                        <div>
                            <span className='trip-description-page-information'>Welcome to Goa, where sun-soaked beaches, vibrant culture, and a laid-back atmosphere create the perfect escape. Nestled on the west coast of India, Goa is renowned for its stunning coastline, historical charm, and lively energy.</span>
                        </div>

                        <div className='pt-4'>
                            <span className='trip-description-page-heading-1'>Trip Details</span>
                        </div>

                        <div>
                            <span className='trip-description-page-information'>Package Name: Goa Bliss Getaway</span>
                            <div>
                                <span className='trip-description-page-information'>Inclusive features:</span>
                                <span>
                                    <ul>
                                        <li>4 days and 1 night stay at a 4-star beachfront resort in North Goa.</li>

                                        <li>Daily buffet breakfast included.</li>

                                        <li>Round-trip economy class flights from major cities.</li>

                                        <li>Full-day guided tour.</li>

                                        <li>24/7 customer support during the trip.</li>

                                        <li>Complimentary access to water sports activities such as parasailing and jet skiing.</li>
                                    </ul>
                                </span>

                                <span className='trip-description-page-information'>Exclusive features:</span>
                                <span>
                                    <ul>
                                        <li>Luxury Accommodations.</li>

                                        <li>Private Transfers.</li>

                                        <li>VIP Access.</li>

                                        <li>Full-day guided tour.</li>

                                        <li>Spa and Wellness.</li>

                                        <li>Option to customize the itinerary.</li>

                                        <li>Photography Service.</li>
                                    </ul>
                                </span>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <span className='trip-description-page-heading-1'>Photos</span>
                            <div className='trip-description-page-carousel-bg-card'>
                                <TripDescriptionPagePhotoCarousel />
                            </div>
                        </div>

                        <div className='pt-5 pb-5'>
                            <span className='trip-description-page-heading-1'>Trip Itinerary</span>

                            <ul class="timeline">
                                <li class="timeline-item" data-date="Day 1">Arrive Goa</li>
                                <li class="timeline-item" data-date="Day 2">North Goa Attraction</li>
                                <li class="timeline-item" data-date="Day 3">South Goa Sightseeing</li>
                                <li class="timeline-item" data-date="Day 4">Departure Day</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-6 ps-5'>
                        <div className=''>
                            {/* <div className='profile-photo-half-align '> */}
                            <div className='position-relative '>
                                <ProfileDetailsTripDescription
                                    className="position-absolute top-0 start-100 translate-middle trip-discription-trip-master-profile-img"
                                />
                            </div>
                        </div>
                        <div className='right-section-trip-description'>
                            <span className='trip-description-page-heading-1'>Tickets</span>
                            <div className='trip-description-ticket-card p-5'>
                                <div className='row py-4'>
                                    <div className='col-6'>
                                        <span className='price-card-trip-page'><span className='rs-sign-trip-page'>₹</span> 10,000/-</span>
                                    </div>
                                    <div className='col-6'>
                                        <IncrementDecrement />
                                    </div>
                                </div>
                                <div className='py-2'>
                                    <span className='trip-description-page-card-time'>Time Left</span>
                                </div>

                                <div className='py-2 trip-description-page-card-time-left'>
                                    <div className='row'>
                                        <div className='col-4 row-center'>10 Days</div>
                                        <div className='col-4 row-center'>24 Hours</div>
                                        <div className='col-4 row-center'>30 Mins</div>
                                    </div>
                                </div>

                                <div className='pt-3'>
                                    <ContinueButton
                                        name="Invite my Tribe"
                                        className="invite-to-tribe-btn w-100"
                                    />
                                </div>
                                <div className='pt-3'>

                                    <ContinueButton
                                        name="Book Now"
                                        className="book-now-btn w-100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='pt-5'>
                            <span className='trip-description-page-heading-1'>Date</span>
                        </div>
                        <div>
                            <i class="fa-regular fa-calendar"></i>
                            <span className='trip-description-page-date ps-3'>14 Jan, 2024 - 17 Jan, 2024 </span>
                        </div>
                        <div className='py-4'>
                            <span className='trip-description-page-heading-1'>Venue</span>
                        </div>
                        <div>
                            <img src={GoaVenue} className='w-100' alt='...' />
                        </div>
                        <div className='py-3'>
                            <span className='trip-description-page-heading-1'>Share</span>
                        </div>
                        <div className='pb-5'>
                            <span className='pe-4'>
                                <i class="fa-brands fa-facebook-f share-fa-icon-size"></i>
                            </span>
                            <span className='pe-4'>
                                <i class="fa-brands fa-instagram share-fa-icon-size"></i>
                            </span>
                            <span className='pe-4'>
                                <i class="fa-brands fa-x-twitter share-fa-icon-size"></i>
                            </span>
                            <span className='pe-4'>
                                <i class="fa-brands fa-linkedin-in share-fa-icon-size"></i>
                            </span>
                            <span className='pe-4'>
                                <i class="fa-regular fa-envelope share-fa-icon-size"></i>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <span className='trip-description-page-heading-1'>Reviews</span>
                    </div>
                    <div className='d-flex py-4'>
                        <img src={ratingStar} alt='...' />
                        <span className='heading-login-modal ps-3'>4.9 rating</span>
                    </div>
                    <div className='row py-4'>
                        <div className='col-2 pe-3'>
                            <span className='particular-rating-overall-heading'>
                                Overall rating
                            </span>

                            <div className='row'>
                                <div className='col-2 row-center'>
                                    <span className='overall-rating-num'>5</span>
                                </div>
                                <div className='col-10 row-center'>
                                    <progress id="progress-bar" value="90" max="100"></progress>
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='col-2 row-center'>
                                    <span className='overall-rating-num'>4</span>
                                </div>
                                <div className='col-10 row-center'>
                                    <progress id="progress-bar" value="50" max="100"></progress>
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='col-2 row-center'>
                                    <span className='overall-rating-num'>3</span>
                                </div>
                                <div className='col-10 row-center'>
                                    <progress id="progress-bar" value="15" max="100"></progress>
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='col-2 row-center'>
                                    <span className='overall-rating-num'>2</span>
                                </div>
                                <div className='col-10 row-center'>
                                    <progress id="progress-bar" value="0" max="100"></progress>
                                </div>
                            </div>

                            <div className='row pt-2'>
                                <div className='col-2 row-center'>
                                    <span className='overall-rating-num'>1</span>
                                </div>
                                <div className='col-10 row-center'>
                                    <progress id="progress-bar" value="0" max="100"></progress>
                                </div>
                            </div>


                        </div>
                        <div className='col-2 overall-rating-border'>
                            <span className='particular-rating-heading'>
                                Value
                            </span>
                            <div className=''>
                                <span className="particular-rating-numbers">4.5</span>
                            </div>
                            <div className='pt-3'>
                                <img src={valueRating} alt='...' />
                            </div>
                        </div>
                        <div className='col-2 overall-rating-border'>
                            <span className='particular-rating-heading'>
                                Meals
                            </span>
                            <div className=''>
                                <span className="particular-rating-numbers">4.2</span>
                            </div>
                            <div className='pt-3'>
                                <img src={mealsRating} alt='...' />
                            </div>
                        </div>
                        <div className='col-2 overall-rating-border'>
                            <span className='particular-rating-heading'>
                                Location
                            </span>
                            <div className=''>
                                <span className="particular-rating-numbers">5.0</span>
                            </div>
                            <div className='pt-3'>
                                <img src={locationRating} alt='...' />
                            </div>
                        </div>
                        <div className='col-2 overall-rating-border'>
                            <span className='particular-rating-heading'>
                                Accomodation
                            </span>
                            <div className=''>
                                <span className="particular-rating-numbers">4.5</span>
                            </div>
                            <div className='pt-3'>
                                <img src={accomodationRating} alt='...' />
                            </div>
                        </div>
                        <div className='col-2 overall-rating-border'>
                            <div className=''>
                                <span className='particular-rating-heading'>
                                    Check-in
                                </span>
                            </div>
                            <div className=''>
                                <span className="particular-rating-numbers">4.3</span>
                            </div>
                            <div className='pt-3'>
                                <img src={checkInRating} alt='...' />
                            </div>
                        </div>
                    </div>


                    {/* REVIEW CARDDDDD */}
                    <div className='row'>
                        <div className='col-6 pb-4'>
                            <ReviewCard />
                        </div>

                        <div className='col-6 pb-4'>
                            <ReviewCard />
                        </div>

                        <div className='col-6 pb-4'>
                            <ReviewCard />
                        </div>

                        <div className='col-6 pb-4'>
                            <ReviewCard />
                        </div>
                    </div>

                    <div className='py-5 row-center'>
                        <ContinueButton
                            name="Book Now"
                            className="book-now-btn"
                        />
                    </div>



                </div>
            </div>

            <FooterBar
                display='none'
            />
        </>
    )
}

export default TripDescriptionPage;