import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import rajasthan1 from '../../assets/images/TripCardHorizontalMulti/Rajasthan/1.png';
import BtnRightArrow from '../Buttons/arrow/rightArrow';
import BtnLeftArrow from '../Buttons/arrow/leftArrow';

const TravelCardCarouselHorizontal = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: (current, next) => setCurrentSlide(next)
    };

    const sliderRef = useRef(null);

    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const goToPrevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 heroSlider-fixed">
                    <Slider ref={sliderRef} {...settings}>
                        <div className='travel-card-carousel'>
                            <img src={rajasthan1} className='img-travel-card-carousel' alt="" />
                        </div>
                        <div className='travel-card-carousel'>
                            <img src={rajasthan1} alt="" className='img-travel-card-carousel' />
                        </div>
                        <div className='travel-card-carousel'>
                            <img src={rajasthan1} alt="" />
                        </div>
                        <div className='travel-card-carousel'>
                            <img src={rajasthan1} alt="" className='img-travel-card-carousel' />
                        </div>
                    </Slider>

                    {currentSlide !== 0 && (
                        <BtnLeftArrow
                            className="travel-carousel-horizontal-left-arrow"
                            onClick={goToPrevSlide}
                        />
                    )}
                    {currentSlide <= settings.responsive[0].settings.slidesToShow - 1 && (
                        <BtnRightArrow
                            className="travel-carousel-horizontal-right-arrow"
                            onClick={goToNextSlide}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TravelCardCarouselHorizontal;
