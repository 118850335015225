import './App.css';
import './assets/css/custom.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/homePage/homePage';
import Login from './pages/loginPage/login';
import BecomeTripMaster from './pages/tripMaster/becomeTripMaster';
import TripPage from './pages/tripPage/tripPage';
import TripDescriptionPage from './pages/tripDescriptionPage/tripDescriptionPage';


function App() {
  return (
    <>
      {/* <HomePage /> */}


      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/twyt-login" element={<Login />} />
          <Route path="/become-trip-master" element={<BecomeTripMaster />} />
          <Route path="/trip-page" element={<TripPage />} />
          <Route path="/trip-description-page" element={<TripDescriptionPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
