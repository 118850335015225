import React, { useState } from 'react';

function DatePicker(props) {
    const [selectedDate, setSelectedDate] = useState('');

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    return (
        <div className="d-flex align-items-center">
            <span className="date-picker-icon">
                <img className="pe-2" src={props.icon ? props.icon + ' ' : ''} alt='...' />
            </span>
            <input
                type="date"
                id="datepicker"
                name="datepicker"
                className='date-picker-custom form-control'
                value={selectedDate}
                onChange={handleDateChange}
            />
        </div>
    );
}

export default DatePicker;
