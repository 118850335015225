import React from 'react';
// import React, { useState } from 'react';
import logoImg from '../../assets/images/logo/twyt-logo.svg';
import { useNavigate } from 'react-router-dom';
// import logoImgBlack from '../../assets/images/logo/twyt-logo-black.svg';

// import ContinueButton from '../Buttons/Continue/continueButton';
// import PhoneText from '../Textbox/phoneText';
// import InputGroup from '../Textbox/inputGroup';
// import ShortText from '../Textbox/shortText';
// import EmailText from '../Textbox/emailText';

// import LeftSectionImg from '../.././assets/images/clipart/LoginModal/LeftSectionImage/LeftSectionImg.png';
// import FirstFourthModalClipart from '../../assets/images/clipart/LoginModal/FourthModal/1.svg'
// import SecondFourthModalClipart from '../../assets/images/clipart/LoginModal/FourthModal/2.svg'
// import FourFourthModalClipart from '../../assets/images/clipart/LoginModal/FourthModal/4.svg'
// import OtpText from '../Textbox/otpText';

function TopNavBar() {



    const navigate = useNavigate();

    const onLoginPage = (e) => {
        e.preventDefault();
        navigate('/twyt-login');
      }




    // const REGISTER_FORM = "RegisterForm";
    // const [phoneNumber, setPhoneNumber] = useState("");
    // const [currentModal, setCurrentModal] = useState(1);

    // const handleNextModal = () => {
    //     setCurrentModal(currentModal + 1);
    // };




    // const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

    // const handlePhoneNumberChange = (value) => {
    //     setPhoneNumber(value);
    //     setIsPhoneNumberValid(validatePhoneNumber(value));
    // };

    // const validatePhoneNumber = (value) => {
    //     return !!value && /^\d{5} \d{5}$/.test(value);
    // };





//     // NAme

//     const [name, setName] = useState("");
//     const isNameValid = name.length >= 3;

//     const handleNameChange = (event) => {
//         setName(event.target.value);
//     };

// // otp

// const [otpValue, setOtpValue] = useState('');
// const isContinueButtonDisabled = otpValue.length !== 5;

//   const handleOtpChange = (otp) => {
//     setOtpValue(otp);
//   };


    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top main-navbar">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <img src={logoImg} alt="logo"></img>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                            <li className="nav-item">
                                <button className="nav-link active hover-nav-item" aria-current="page" href="#">Trip Planner</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link hover-nav-item">Things To Do</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link hover-nav-item">Tours</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link hover-nav-item">Itineraries</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link hover-nav-item">Hotels </button>
                            </li>
                            <li className="nav-item ms-3">







                                <button className="login-btn hover-effect-white" type="submit" onClick={onLoginPage}>
                                    Login / Signup
                                </button>
                                {/* <button className="login-btn hover-effect-white" type="submit" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">
                                    Login / Signup
                                </button> */}
                                {/* <div className=''>
                                    <div className="modal fade modal-xl" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content login-modal-content">
                                                <div className="modal-body p-0">
                                                    <div className="row">
                                                        {currentModal !== 4 && (
                                                            <div className="col-6 login-modal-left-section" >

                                                                <div className='row'>
                                                                    <div className='col pt-5'>
                                                                        {currentModal !== 3 && (
                                                                            <span className='login-modal-head-font-white px-3'>Together with your<br /><span className='login-modal-head-font-yellow  px-3'>Tribe.</span></span>
                                                                        )}
                                                                        {currentModal === 3 && (
                                                                            <span className='login-modal-head-font-white-modal-three px-3'>Curated experiences <span className='px-3'>for</span><span className='login-modal-head-font-yellow-modal-three'>You.</span></span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col py-5'>
                                                                        <img className="w-100 px-3" src={LeftSectionImg} alt="..." />
                                                                    </div>
                                                                </div>
                                                                {currentModal !== 3 && (

                                                                    <div className='row'>
                                                                        <div className='col'>
                                                                            <span className='sub-heading-login-modal row-center'>Plan your experience with us.</span>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        )}
                                                        <div className="col">
                                                            {currentModal === 1 && (
                                                                <>
                                                                    <div className='py-5'>
                                                                        <div className='login-modal-logo pb-5'>
                                                                            <img src={logoImgBlack} alt='...' />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <span className='heading-3-home-black pb-3'>Login or Signup to continue<span className='ps-2 text-fullstop'>.</span></span>
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <InputGroup
                                                                                label=""
                                                                                CustomClass=""
                                                                                control={
                                                                                    <PhoneText
                                                                                        id="txtPhoneNumber"
                                                                                        name="txtPhoneNumber"
                                                                                        PhoneTextClasses="form-control style-textbox mb-3"
                                                                                        placeholder="Enter your Mobile Number"
                                                                                        required={true}
                                                                                        form={REGISTER_FORM}
                                                                                        value={phoneNumber}
                                                                                        onChange={(e) => handlePhoneNumberChange(e.target.value)}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <ContinueButton
                                                                                className="mt-4"
                                                                                onClick={handleNextModal}
                                                                                name="Continue"
                                                                                disabled={!isPhoneNumberValid}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {currentModal === 2 && (
                                                                <>
                                                                    <div className='py-5'>


                                                                        <div className='login-modal-logo pb-5'>
                                                                            <img src={logoImgBlack} alt='...' />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <span className='heading-3-home-black pb-3'>Enter your OTP<span className='ps-2 text-fullstop'>.</span></span>
                                                                        </div>

                                                                        <OtpText 
                                                                        onOtpChange={handleOtpChange}
                                                                        />
                                                                        <div className='row-center'>
                                                                            <ContinueButton 
                                                                                className="mt-4"
                                                                                onClick={handleNextModal}
                                                                                name="Continue"
                                                                                disabled={isContinueButtonDisabled}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {currentModal === 3 && (
                                                                <>
                                                                    <div className='py-5'>
                                                                        <div className='login-modal-logo pb-5'>
                                                                            <img src={logoImgBlack} alt='...' />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <span className='heading-3-home-black pb-3'>We would love to know more about you!<span className='ps-2 text-fullstop'></span></span>
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <InputGroup
                                                                                label=""
                                                                                control={
                                                                                    <ShortText
                                                                                        placeholder="Enter your Name"
                                                                                        ShortTextClasses="style-textbox"
                                                                                        onChange={handleNameChange}
                                                                                        value={name}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <InputGroup
                                                                                label=""
                                                                                control={
                                                                                    <EmailText
                                                                                        placeholder="Enter your Email ID"
                                                                                        EmailTextClasses="style-textbox"
                                                                                    />
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <ContinueButton
                                                                                className="mt-4"
                                                                                onClick={handleNextModal}
                                                                                name="Continue"
                                                                                disabled={!isNameValid}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {currentModal === 4 && (
                                                                <>
                                                                    <div className='fourth-modal-align'>


                                                                        <div className='login-modal-logo'>
                                                                            <img src={logoImgBlack} alt='...' />
                                                                        </div>
                                                                        <div className='row-center '>
                                                                            <span className='heading-login-modal'>Please tell us a little more about your interests!<span className='ps-2 text-fullstop'></span></span>
                                                                        </div>
                                                                        <div className='row-center button-text-row'>
                                                                            <span className='modal-intrest-sub-heading mt-3'>OUTDOOR ACTIVITIES</span>
                                                                        </div>
                                                                        <div className='button-modal'>
                                                                            <button class="intrest-button ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FirstFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Hiking</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Camping</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Swimming</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FourFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Swimming</span>
                                                                            </button>
                                                                        </div>

                                                                        <div className='button-modal '>
                                                                            <button class="intrest-button ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FirstFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Skiing</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Wildlife</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Stargazing</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FourFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Stargazing</span>
                                                                            </button>
                                                                        </div>


                                                                        <div className='row-center button-text-row'>
                                                                            <span className='mt-3 modal-intrest-sub-heading'>EXPERIENCE SPECIFIC</span>
                                                                        </div>
                                                                        <div className='button-modal'>
                                                                            <button class="intrest-button ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FirstFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Hiking</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Camping</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Swimming</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FourFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Swimming</span>
                                                                            </button>
                                                                        </div>

                                                                        <div className='button-modal'>
                                                                            <button class="intrest-button ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FirstFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Skiing</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Wildlife</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={SecondFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Stargazing</span>
                                                                            </button>
                                                                            <button class="intrest-button  ms-3 mt-3" type="button">
                                                                                <span class=""><img src={FourFourthModalClipart} alt="..." /></span>
                                                                                <span class="intrest-button-text">Stargazing</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className='row-center'>
                                                                            <ContinueButton
                                                                                className="mt-5 w-50"
                                                                                name="Continue"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div> */}










                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default TopNavBar