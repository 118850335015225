import React from 'react'
import TopNavBar from '../../components/navbars/topNavBar'
import FooterBar from '../../components/navbars/footerBar'

import DropdownFormat from '../../components/controls/searchBars/dropdownFormat'
import BtnCircleSearch from '../../components/controls/searchBars/circleSearchButton'

import ageGroupIcon from '../../assets/images/icons/ageGroup.svg'
import tripTypeIcon from '../../assets/images/icons/tripType.svg'
import tripLocationIcon from '../../assets/images/icons/tripLocation.svg'
import selectDateIcon from '../../assets/images/icons/selectDate.svg'

// Featured Experiences icons
import allFeaturedExperiences from '../../assets/images/icons/featuredExperiences/all.svg'
import oneFeaturedExperiences from '../../assets/images/icons/featuredExperiences/1.svg'
import twoFeaturedExperiences from '../../assets/images/icons/featuredExperiences/2.svg'
import threeFeaturedExperiences from '../../assets/images/icons/featuredExperiences/3.svg'
import fourFeaturedExperiences from '../../assets/images/icons/featuredExperiences/4.svg'
import fiveFeaturedExperiences from '../../assets/images/icons/featuredExperiences/5.svg'
import sixFeaturedExperiences from '../../assets/images/icons/featuredExperiences/6.svg'
import sevenFeaturedExperiences from '../../assets/images/icons/featuredExperiences/7.svg'
import eightFeaturedExperiences from '../../assets/images/icons/featuredExperiences/8.svg'
import nineFeaturedExperiences from '../../assets/images/icons/featuredExperiences/9.svg'
// import TravelCardHorizontal from '../../components/cards/TravelCard/travelCardHorizontal'

import AshwinPhoto from '../../assets/images/tripMasterPhoto/Ashwin.png'

// import Rajasthan from '../../assets/images/backgound/FeaturedExperiences/Rajasthan.png';
// import WeekenderPune from '../../assets/images/backgound/FeaturedExperiences/WeekenderPune.png';
// import Uttarakhand from '../../assets/images/backgound/FeaturedExperiences/Uttarakhand.png';
// import ZeroFestival from '../../assets/images/backgound/FeaturedExperiences/ZeroFestival.png';
import BtnRightArrow from '../../components/Buttons/arrow/rightArrow'
import FindExperiences from '../../components/Buttons/FindExperiences/findExperiences'

// Join Our Community
import WeekenderPuneSq from '../../assets/images/backgound/JoinOurCommunity/WeekenderPuneSq.png';
import RajasthanSq from '../../assets/images/backgound/JoinOurCommunity/RajasthanSq.png';
import UttarakhandSq from '../../assets/images/backgound/JoinOurCommunity/UttarakhandSq.png';
import AshwinCircle from '../../assets/images/JoinOurCommunityPhoto/Ashwin.png';
import SunnyCircle from '../../assets/images/JoinOurCommunityPhoto/Sunny.png';
import GirlCircle from '../../assets/images/JoinOurCommunityPhoto/Girl.png';
import SunnySmallCircle from '../../assets/images/JoinOurCommunityPhoto/SunnySmall.png';
import AshwinSmallCircle from '../../assets/images/JoinOurCommunityPhoto/AshwinSmall.png';
import GirlSmallCircle from '../../assets/images/JoinOurCommunityPhoto/GirlSmall.png';

import Blog1 from '../../assets/images/backgound/Blogs/Blog1.png'
import ButtonReadMore from '../../components/Buttons/ReadMore/ButtonReadMore'

// import AllPassionate from "../../assets/images/backgound/PassionateAboutTravel/All.png"
import PassionateButton from '../../components/Buttons/Passionate/passionateButton'

import btnCoverImg from "../../assets/images/backgound/PassionateAboutTravel/CoverImage/Cover1.png"
import TravelCardVertical from '../../components/cards/TravelCard/travelCardVertical'
import CommunityProfileSmallCard from '../../components/cards/CommunityCard/communityProfileSmallCard'
import InfluencerCarousel from '../../components/Carousel/influencerCarousel'
import PassionateAboutTravel from '../../components/Carousel/passionateAboutTravel'
import RoundImageTextCard from '../../components/cards/roundImageTextCard'

// How TWYT Works

import treeWaterImg from '../../assets/images/clipart/tree-water.svg';
import bagCameraImg from '../../assets/images/clipart/bag-camera.svg';
import bottleSunglassesImg from '../../assets/images/clipart/bottle-sunglasses.svg';
import flightTicketCalendarImg from '../../assets/images/clipart/flight-ticket-calendar.svg';

// Ask Us?
import askMaskImg from '../../assets/images/ask-mask.png';
import InfluencerCarouselMobile from '../../components/Carousel/influencerCarouselMobile'
import { useNavigate } from 'react-router-dom'
import RouteCardHorizontal from '../../components/cards/RouteCard/routeCardHorizontal'


// Route Trip
import BaliBackground from "../../assets/images/OurRouteTrips/Bali.png"
import profilePic from "../../assets/images/OurRouteTrips/ProfilePic.png"
import TravelCardMultiImageHorizontal from '../../components/cards/TravelCard/NewMultiImage/travelCardMultiImageHorizontal'
import DatePicker from '../../components/controls/searchBars/datePicker'

function HomePage() {


  const data = [
    { image: allFeaturedExperiences, text: 'All', age: '18-25' },
    { image: oneFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: twoFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: threeFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: fourFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: fiveFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: sixFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: sevenFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: eightFeaturedExperiences, text: 'Age Group', age: '18-25' },
    { image: nineFeaturedExperiences, text: 'Age Group', age: '18-25' }
  ];


  const navigate = useNavigate();

  const onBeacomeTripMaster = (e) => {
    e.preventDefault();
    navigate('/become-trip-master');
  }



  const onTripPage = (e) => {
    e.preventDefault();
    navigate('/trip-page');
  }

  return (
    <>
      <TopNavBar />
      <main className=''>


        {/* Poster Image */}
        <div className='in-poster-align'>
          <div className='image-background'>
            <div className='container-fluid h-100'>
              <div className='row h-100 align-items-center'>
                <div className='col-12 text-center'>
                  <div className='col-12 ps-5'>
                    {/* <span className='home-poster-heading'>Together with <br />your <span className='text-color-yellow'>Tribe.</span></span> */}
                    <span className='home-poster-heading'>Together with <br />your Tribe.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Search Bar */}
          <div className="container-fluid in-poster-half-align row-center d-flex justify-content-center align-items-center">
            <div className="d-flex flex-wrap filter-bar">
              <DropdownFormat
                className="ms-3 centerBar-item"
                icon={ageGroupIcon}
                name="Age Group"
                options={[
                  {
                    text: "18-25",
                    value: "V18-25"
                  },
                  {
                    text: "26-35",
                    value: "V26-35"
                  },
                  {
                    text: "36-50",
                    value: "V36-50"
                  }
                ]}
              />
              <DropdownFormat
                className="centerBar-item"
                icon={tripTypeIcon}
                name="Trip Type"
                options={[
                  {
                    text: "Adventure Travel",
                    value: "Adventure Travel"
                  },
                  {
                    text: "Business Travel",
                    value: "Business Travel"
                  },
                  {
                    text: "Cultural Travel",
                    value: "Cultural Travel"
                  }
                ]}
              />
              <DropdownFormat
                className="centerBar-item"
                icon={tripLocationIcon}
                name="Trip Location"
                options={[
                  {
                    text: "Lakshadweep",
                    value: "Lakshadweep"
                  },
                  {
                    text: "Lonavala",
                    value: "Lonavala"
                  },
                  {
                    text: "Manali",
                    value: "Manali"
                  },
                  {
                    text: "Goa",
                    value: "Goa"
                  }
                ]}
              />
              <DatePicker
                label=""
                icon={selectDateIcon}
                className="centerBar-item"
              />


              <BtnCircleSearch />
            </div>
          </div>
















        </div>

        {/* Featured Experiences */}
        <div className="pt-5">
          <div className="container py-5">
            <div className='row'>
              <span className="heading-2-home-black">Featured Experiences</span>
            </div>
            <div className='container'>
              <div className='d-flex flex-wrap feature-icon-row py-5'>
                {data.map((item, index) => (
                  <div key={index} className={`c ${index === 0 ? 'active-underline-featured' : ''}`}>
                    <div className='row icon-align-featuredExperiences'>
                      <img className='icon-featuredExperiences' src={item.image} alt='...' />
                    </div>
                    <div className='row'>
                      <span className='icon-name-featuredExperiences'>{item.text}</span>
                    </div>
                    <div className='row'>
                      <span className='icon-age-featuredExperiences'>{item.age}</span>
                    </div>
                  </div>
                ))}

                <div className=' row-right'>
                  <BtnRightArrow />
                </div>
              </div>
            </div>
            <div className=''>
              <div className='row'>
                {/* <div className='col-sm-12 col-md-6 col-lg-3'> */}

                {/* <TravelCardHorizontal
                    background={Rajasthan}
                    trend="TRAVEL"
                    trendIcon="fa-solid fa-fire-flame-curved"
                    plan="Rajasthan"
                    rating="4.3"
                    planType="Travel/Sightseeing"
                    seatLeft="56/100"
                    plannerPhoto={AshwinPhoto}
                    plannerName="Ashwin"
                    plannerProfile="Trip Master"
                  /> */}

                {/* <TravelCardMultiImageHorizontal
                    className=""
                    profilePic={AshwinCircle}
                    profileName="Ashwin"
                    profileTitle="Verified TripMaster"
                    backgroundImage={BaliBackground}
                    city="Bali, "
                    country="Indonesia"
                    cityDescription="Historical Monument"
                    pricing="₹ 12,000"
                  /> */}
                {/* </div> */}
                <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                  {/* <TravelCardHorizontal
                    background={WeekenderPune}
                    trend="EVENTS"
                    trendIcon="fa-solid fa-fire-flame-curved"
                    plan="Weekender Pune"
                    rating="4.3"
                    planType="Events/Music"
                    seatLeft="56/100"
                    plannerPhoto={AshwinPhoto}
                    plannerName="Sunny"
                    plannerProfile="Trip Master"
                  /> */}
                  <TravelCardMultiImageHorizontal
                    className=""
                    profilePic={AshwinCircle}
                    profileName="Ashwin"
                    profileTitle="Verified TripMaster"
                    backgroundImage={BaliBackground}
                    city="Bali, "
                    country="Indonesia"
                    cityDescription="Historical Monument"
                    pricing="₹ 12,000"
                  />
                </div>
                <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                  {/* <TravelCardHorizontal
                    background={Uttarakhand}
                    trend="TRAVEL"
                    trendIcon="fa-solid fa-fire-flame-curved"
                    plan="Uttarakhand"
                    rating="4.3"
                    planType="Travel/Sightseeing"
                    seatLeft="56/100"
                    plannerPhoto={AshwinPhoto}
                    plannerName="Ashwin"
                    plannerProfile="Trip Master"
                  /> */}
                  <TravelCardMultiImageHorizontal
                    className=""
                    profilePic={AshwinCircle}
                    profileName="Ashwin"
                    profileTitle="Verified TripMaster"
                    backgroundImage={BaliBackground}
                    city="Bali, "
                    country="Indonesia"
                    cityDescription="Historical Monument"
                    pricing="₹ 12,000"
                  />
                </div>
                <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                  {/* <TravelCardHorizontal
                    background={ZeroFestival}
                    trend="EVENTS"
                    trendIcon="fa-solid fa-fire-flame-curved"
                    plan="Zero Festival"
                    rating="4.3"
                    planType="Events/Music"
                    seatLeft="56/100"
                    plannerPhoto={AshwinPhoto}
                    plannerName="Ashwin"
                    plannerProfile="Trip Master"
                  /> */}
                  <TravelCardMultiImageHorizontal
                    className=""
                    profilePic={AshwinCircle}
                    profileName="Ashwin"
                    profileTitle="Verified TripMaster"
                    backgroundImage={BaliBackground}
                    city="Bali, "
                    country="Indonesia"
                    cityDescription="Historical Monument"
                    pricing="₹ 12,000"
                  />
                </div>
              </div>
            </div>
            <FindExperiences
              className="mt-5 py-2 px-4 btn-featuredExperiences"
              name="Find Experiences" />
          </div>
        </div>


        {/* Influnecers */}
        <div className="container-fluid influnecers-section py-5 d-none d-md-block d-lg-block">
          <span className='heading-2-home-white row-center'>Our Influencer Endorsed Trips!</span>
          <div className='pt-5'>
            <InfluencerCarousel />
          </div>
        </div>


        <div className="container-fluid influnecers-section py-5 d-block d-sm-none">
          <span className='heading-2-home-white row-center'>Our Influencer Endorsed Trips!</span>
          <div className=''>
            <InfluencerCarouselMobile />
          </div>
        </div>

        {/* Our Top Trending Experiences */}
        <div className="container py-5">
          <div className='row pb-5'>
            <span className="heading-1-home-black">Our Top Trending Trips</span>
          </div>
          <div className=''>
            <div className='row pb-2'>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>

                {/* <TravelCardHorizontal
                  background={Rajasthan}
                  trend="TRAVEL"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Rajasthan"
                  rating="4.3"
                  planType="Travel/Sightseeing"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                {/* <TravelCardHorizontal
                  background={WeekenderPune}
                  trend="EVENTS"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Weekender Pune"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Sunny"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                {/* <TravelCardHorizontal
                  background={Uttarakhand}
                  trend="TRAVEL"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Uttarakhand"
                  rating="4.3"
                  planType="Travel/Sightseeing"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              {/* <div className='col-sm-12 col-md-6 col-lg-3'> */}
              {/* <TravelCardHorizontal
                  background={ZeroFestival}
                  trend="EVENTS"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Zero Festival"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
              {/* <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                /> */}
              {/* </div> */}
            </div>
          </div>

          <FindExperiences
            className="mt-5 py-2 px-4 btn-featuredExperiences"
            name="Explore Now" />
        </div>

        {/* Our Top Trending Events */}
        <div className="container py-5">
          <div className='row pb-5'>
            <span className="heading-1-home-black">Our Top Trending Events</span>
          </div>
          <div className=''>
            <div className='row pb-2'>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>

                {/* <TravelCardHorizontal
                  background={Rajasthan}
                  trend="TRAVEL"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Rajasthan"
                  rating="4.3"
                  planType="Travel/Sightseeing"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                {/* <TravelCardHorizontal
                  background={WeekenderPune}
                  trend="EVENTS"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Weekender Pune"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Sunny"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
                {/* <TravelCardHorizontal
                  background={Uttarakhand}
                  trend="TRAVEL"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Uttarakhand"
                  rating="4.3"
                  planType="Travel/Sightseeing"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
                <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                />
              </div>
              {/* <div className='col-sm-12 col-md-6 col-lg-3'> */}
              {/* <TravelCardHorizontal
                  background={ZeroFestival}
                  trend="EVENTS"
                  trendIcon="fa-solid fa-fire-flame-curved"
                  plan="Zero Festival"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Ashwin"
                  plannerProfile="Trip Master"
                /> */}
              {/* <TravelCardMultiImageHorizontal
                  className=""
                  profilePic={AshwinCircle}
                  profileName="Ashwin"
                  profileTitle="Verified TripMaster"
                  backgroundImage={BaliBackground}
                  city="Bali, "
                  country="Indonesia"
                  cityDescription="Historical Monument"
                  pricing="₹ 12,000"
                /> */}
              {/* </div> */}
            </div>
          </div>

          <FindExperiences
            className="mt-5 py-2 px-4 btn-featuredExperiences"
            name="Explore Now" />
        </div>

        {/* Passionate about Travel? */}
        <div className="container-fluid passionate-section my-5">
          <div className='container'>


            <div className='row'>
              {/* <div className='col-1'>

              </div> */}
              <div className='col-sm-12 col-md-6 col-lg-6 '>
                {/* <img className='w-100 passionate-image' src={AllPassionate} alt='...' /> */}

                <PassionateAboutTravel />
              </div>
              <div className='col-sm-12 col-md-5 col-lg-6 p-5'>
                <div className='row'>
                  <span className='heading-2-home-white'>
                    Passionate about Travel?
                  </span>
                </div>
                <div className='row'>
                  <span className='heading-4-home-white pt-3 pb-5'>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its <br /> layout.
                  </span>
                </div>
                <div className='row py-4'>
                  <PassionateButton
                    btnCoverImg={btnCoverImg}
                    name="Become a TripMaster"
                    onClick={onBeacomeTripMaster}
                    />
                </div>
                <div className='row'>
                  <PassionateButton
                    btnCoverImg={btnCoverImg}
                    name="Find Experiences"
                    onClick={onTripPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Our Route Trips */}
        <div className="container my-5">
          <div className='row' >
            <span className='row-center heading-1-home-black pb-5'>
              Our Route Trips
            </span>
          </div>

          <div className='row'>
            <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>

              <RouteCardHorizontal
                className=""
                profilePic={profilePic}
                profileName="Thomas Cook"
                profileTitle="Vendor/Author Party"
                backgroundImage={BaliBackground}
                city="Bali, "
                country="Indonesia"
                cityDescription="Historical Monument"
                pricing="₹ 12,000"
              />
            </div>

            <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>

              <RouteCardHorizontal
                className=""
                profilePic={profilePic}
                profileName="Thomas Cook"
                profileTitle="Vendor/Author Party"
                backgroundImage={BaliBackground}
                city="Bali, "
                country="Indonesia"
                cityDescription="Historical Monument"
                pricing="₹ 12,000"
              />
            </div>


            <div className='row-center col-sm-12 col-md-6 col-lg-4 mt-4'>
              <RouteCardHorizontal
                className=""
                profilePic={profilePic}
                profileName="Thomas Cook"
                profileTitle="Vendor/Author Party"
                backgroundImage={BaliBackground}
                city="Bali, "
                country="Indonesia"
                cityDescription="Historical Monument"
                pricing="₹ 12,000"
              />
            </div>

          </div>

        </div>


        {/* Join our community of Trip Masters - create your own trip and invite people to join! */}
        <div className='container py-5'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-6'>

              <span className='heading-1-2-home-black'>Join our community of Trip Masters - create your own trip and invite people to join!</span>
              <FindExperiences
                className="mt-5 py-2 px-4"
                name="Start Planning"
                onClick={onBeacomeTripMaster}
              />
              <br />

              <div className='d-none d-lg-block'>


                <div className='row'>
                  <div className='col-5'>
                    <img src={SunnyCircle} className='pt-5 ps-5' alt='...' />
                    <div className="profile-card-overlay-sunny">
                      <CommunityProfileSmallCard
                        smallImg={SunnySmallCircle}
                        className="community-card-sunny"
                        tripTitle="Trip Sensei"
                        totalTrips="10+ Trips"
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-5'>
                    <img src={GirlCircle} className='photo-community-circle-girl' alt='...' />
                    <div className="profile-card-overlay-girl">
                      <CommunityProfileSmallCard
                        smallImg={GirlSmallCircle}
                        className="community-card-girl"
                        tripTitle="Trip Sensei"
                        totalTrips="5+ Trips"
                      />
                    </div>
                  </div>


                  <div className='col-7'>
                    <img src={AshwinCircle} className='photo-community-circle-ashwin' alt='...' />
                    <div className="profile-card-overlay-ashwin">
                      <CommunityProfileSmallCard
                        smallImg={AshwinSmallCircle}
                        className="community-card-ashwin"
                        tripTitle="Trip Sensei"
                        totalTrips="5+ Trips"
                      />
                    </div>
                  </div>
                </div>

              </div>


              {/* Mobile circle img */}
              <div className='d-md-none d-lg-none d-xl-none circle-mobile-center row-center'>

                <div className='row'>
                  <div className=''>
                    <img src={SunnyCircle} className='pt-5' alt='...' />
                    <div className="profile-card-overlay-sunny">
                      <CommunityProfileSmallCard
                        smallImg={SunnySmallCircle}
                        className="community-card-sunny"
                        tripTitle="Trip Sensei"
                        totalTrips="10+ Trips"
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className=''>
                    <img src={GirlCircle} className='photo-community-circle-girl' alt='...' />
                    <div className="profile-card-overlay-girl">
                      <CommunityProfileSmallCard
                        smallImg={GirlSmallCircle}
                        className="community-card-girl"
                        tripTitle="Trip Sensei"
                        totalTrips="5+ Trips"
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <img src={AshwinCircle} className='photo-community-circle-ashwin' alt='...' />
                  <div className="profile-card-overlay-ashwin row-center">
                    <CommunityProfileSmallCard
                      smallImg={AshwinSmallCircle}
                      className="community-card-ashwin"
                      tripTitle="Trip Sensei"
                      totalTrips="5+ Trips"
                    />
                  </div>
                </div>

              </div>




            </div>
            <div className='col-sm-12 col-md-12 col-lg-6'>
              <div className='row'>
                <TravelCardVertical
                  background={WeekenderPuneSq}
                  plan="Weekender Pune"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Sunny"
                  plannerProfile="Trip Master"
                />
              </div>
              <div className='row'>
                <TravelCardVertical
                  background={RajasthanSq}
                  plan="Weekender Pune"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Sunny"
                  plannerProfile="Trip Master"
                />
              </div>
              <div className='row'>
                <TravelCardVertical
                  background={UttarakhandSq}
                  plan="Weekender Pune"
                  rating="4.3"
                  planType="Events/Music"
                  seatLeft="56/100"
                  plannerPhoto={AshwinPhoto}
                  plannerName="Sunny"
                  plannerProfile="Trip Master"
                />
              </div>
            </div>
          </div>

        </div>


        {/* BLOGS */}
        <div className="container pb-5">
          <div className='row' >
            <span className='row-center heading-1-home-black pb-5'>
              Blog
            </span>
          </div>
          <div className='row blog-section'>
            <div className='col-sm-12 col-md-6 col-lg-4 mb-5 row-center'>
              <div className="card card-blog-horizontal">
                <img src={Blog1} className="card-img-top blog-card-image-hover" alt="..." />
                <div className="card-body">
                  <div className='row'>
                    <span className="card-title blog-card">Blogs</span>
                  </div>
                  <div className='row'>
                    <span className="card-text blog-card-title pt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                  </div>
                  <div className='row'>
                    <span className='blog-card-name pt-2 pb-3'>By Harshal / July 3, 2023</span>
                  </div>
                  <ButtonReadMore
                    name="Read More"
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4 mb-5 row-center'>
              <div className="card card-blog-horizontal">
                <img src={Blog1} className="card-img-top blog-card-image-hover" alt="..." />
                <div className="card-body">
                  <div className='row'>
                    <span className="card-title blog-card">Blogs</span>
                  </div>
                  <div className='row'>
                    <span className="card-text blog-card-title pt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                  </div>
                  <div className='row'>
                    <span className='blog-card-name pt-2 pb-3'>By Harshal / July 3, 2023</span>
                  </div>
                  <ButtonReadMore
                    name="Read More"
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4 mb-5 row-center'>
              <div className="card card-blog-horizontal">
                <img src={Blog1} className="card-img-top blog-card-image-hover" alt="..." />
                <div className="card-body">
                  <div className='row'>
                    <span className="card-title blog-card">Blogs</span>
                  </div>
                  <div className='row'>
                    <span className="card-text blog-card-title pt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                  </div>
                  <div className='row'>
                    <span className='blog-card-name pt-2 pb-3'>By Harshal / July 3, 2023</span>
                  </div>
                  <ButtonReadMore
                    name="Read More"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* How TWYT Works */}

        <div className="container-fluid p-5">
          <h1 className="section-highlite-text">How Twyt works</h1>
          <p className='section-small-highlite-text text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

          <div className="px-5 mx-5 mt-5">
            <ul className="nav nav-tabs justify-content-center">
              <li className="nav-item">
                <a className="nav-link active twyt-works heading-3-home-black" aria-current="page" href="/">For Travellers</a>
              </li>
              <li className="nav-item">
                <a className="nav-link heading-3-home-black" href="/">For TripMasters</a>
              </li>
            </ul>
          </div>
          {/* <div className="row"> */}
          <div className="container row-center pt-5 d-flex  flex-wrap">
            {/* <div className="col-md-6 col-lg-3 py-5 row-center"> */}
            <RoundImageTextCard
              imgSrc={treeWaterImg}
              bgColor='#D6EBEB'
              btnColor='#06807D'
            />
            {/* </div> */}
            {/* <div className="col-md-6 col-lg-3 p-5 row-center"> */}
            <RoundImageTextCard
              imgSrc={bagCameraImg}
              bgColor='#FFE6E2'
              btnColor='#FD7156'
            />
            {/* </div> */}
            {/* <div className="col-md-6 col-lg-3 py-5 row-center"> */}
            <RoundImageTextCard
              imgSrc={bottleSunglassesImg}
              bgColor='#FCF2DB'
              btnColor='#FFB100'
            />
            {/* </div> */}
            {/* <div className="col-md-6 col-lg-3 p-5 row-center"> */}
            <RoundImageTextCard
              imgSrc={flightTicketCalendarImg}
              bgColor='#E2F5E1'
              btnColor='#79a776'
            />
            {/* </div> */}
          </div>
        </div>


        {/* Ask Us? */}
        <section className="clip-path-bar">&nbsp;</section>
        <section className="section-ask-us">
          <div className="container p-5">
            <span className="row-center section-highlite-text">Confused about your Travel? Ask us.</span>
            <div className="d-flex justify-content-center my-5">
              <div className="card-ask-us">
                <img src={askMaskImg} className="me-5" alt="..." />
                <div className="card-body-ask">
                  <span className="card-ask-us-title mb-4">Confused about your Travel? Ask us.</span>
                  <button className="default-btn hover-effect-blue">Get in Touch!</button>
                </div>
              </div>
            </div>
          </div>
        </section>


      </main>


      <FooterBar />
    </>
  )
}

export default HomePage