// import React from 'react'

// function DropdownFormat(props) {

//     return (
//         <>
//             <div className={`dropdown ${props.className}`}>
//                 <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
//                     <img className="pe-2" src={props.icon ? props.icon + ' ' : ''} alt='...'/>
//                     <span className="pe-5 searchDropdownText">
//                         {props.name ? props.name : ""}
//                     </span>
//                 </button>
//                 <ul className="dropdown-menu">
//                     <li><a className="dropdown-item" href="/">Action</a></li>
//                     <li><a className="dropdown-item" href="/">Another action</a></li>
//                     <li><a className="dropdown-item" href="/">Something else here</a></li>
//                 </ul>
//             </div>
//         </>
//     )
// }

// export default DropdownFormat;

// import React, { useEffect, useState } from 'react'

// function DropdownFormat(props) {

//     const [value, setValue] = useState(props.value ? props.value : "");
//     const [optionsData, setOptionsData] = useState(props.options);

//     useEffect(() => {
//         setOptionsData(props.options);
//     }, [props.options]);

//     useEffect(() => {
//         setValue(props.value);
//     }, [props.value]);

//     const onSelected = async (e, v) => {
//         e.stopPropagation();

//         setValue(v);
//         // let _e = { ...e };
//         // _e.target["name"] = props.name;
//         // props.onChange && props.onChange(_e, value);
//     };

//     let options = [];

//     if (optionsData && Array.isArray(optionsData) && optionsData.length > 0) {
//         options = optionsData.map((o, index) => (
//             <li key={o.value} className="dropdown-item" onClick={(e) => onSelected(e, o.value)}>
//                 <span className="dropdown-item" href="#">{o.text}</span>
//             </li>
//         ));
//     }

//     const getTextFromValue = (value) => {

//         const matchingObject = optionsData.find(item => item.value === value);

//         if (matchingObject) {
//             return matchingObject.text;
//         } else {
//             return "";
//         }
//     }

//     return (
//         <>
//             <div className={`dropdown ${props.className}`}>
//                 <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
//                     <img className="pe-2" src={props.icon ? props.icon + ' ' : ''} alt='...' />
//                     <span className="pe-5 searchDropdownText">
//                         {value ? getTextFromValue(value) :
//                             props.name ? props.name : ""}
//                     </span>
//                 </button>
//                 <ul className="dropdown-menu">
//                     {options}
//                     {/* <li><a className="dropdown-item" href="#">Action</a></li>
//                     <li><a className="dropdown-item" href="#">Another action</a></li>
//                     <li><a className="dropdown-item" href="#">Something else here</a></li> */}
//                 </ul>
//             </div>
//         </>
//     )
// }

// export default DropdownFormat;







import React, { useEffect, useState } from 'react';

function DropdownFormat(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [optionsData, setOptionsData] = useState(props.options);

    useEffect(() => {
        setOptionsData(props.options);
    }, [props.options]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onSelected = async (e, v) => {
        e.stopPropagation();
        setValue(v);

        const dropdown = document.getElementById(`${props.name}-dropdown`);
        if (dropdown && window.bootstrap) {
            const bootstrapDropdown = new window.bootstrap.Dropdown(dropdown);
            bootstrapDropdown.hide();
        }

    };

    let options = [];

    if (optionsData && Array.isArray(optionsData) && optionsData.length > 0) {
        options = optionsData.map((o, index) => (
            <li key={o.value} className="dropdown-item" onClick={(e) => onSelected(e, o.value)}>
                <span className="dropdown-item" href="#">{o.text}</span>
            </li>
        ));
    }

    const getTextFromValue = (value) => {
        const matchingObject = optionsData.find(item => item.value === value);
        return matchingObject ? matchingObject.text : "";
    };

    return (
        <>
            <div className={`dropdown ${props.className}`}>
                <button
                    id={`${props.name}-dropdown`}
                    className="btn dropdown-toggle dropdown-search-bar"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img className="pe-2" src={props.icon ? props.icon + ' ' : ''} alt='...' />
                    <span className="pe-5 searchDropdownText">
                        {value ? getTextFromValue(value) : props.name ? props.name : ""}
                    </span>
                </button>
                <ul className="dropdown-menu">
                    {options}
                </ul>
            </div>
        </>
    );
}

export default DropdownFormat;
