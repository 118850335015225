import React from 'react';

function ButtonReadMore(props) {
    return (
        <>
            {/* <button type="button" className='btn-readMore hover-effect-blue btn-learnMore'> */}
            <button type="button" className={`btn-readMore hover-effect-blue ${props.className}`} onClick={props.onClick} aria-hidden = {props.ariaHidden}
>
                {props.name}
            </button>
        </>
    );
}

export default ButtonReadMore;
