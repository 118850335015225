import React from 'react';

function ContinueButton(props) {
    return (
        <>
            <button
                type="button"
                onClick={props.onClick}
                className={`${props.className} btn-continueButton ${props.disabled ? 'btn-disabled-continue' : ''}`}
                disabled={props.disabled}
            >
                {props.name}
            </button>
        </>
    );
}

export default ContinueButton;
