import React, { useState } from 'react';
import jeromeBellPic from '../../../assets/images/ProfilePhoto/JeromeBell.png'

function ReviewCard(props) {
    const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
    const [thumbsDownClicked, setThumbsDownClicked] = useState(false);
    const [thumbsUpCount, setThumbsUpCount] = useState(0);
    const [thumbsDownCount, setThumbsDownCount] = useState(0);

    const handleThumbsUpClick = () => {
        if (!thumbsDownClicked) {
            setThumbsUpClicked(!thumbsUpClicked);
            setThumbsUpCount(thumbsUpClicked ? 0 : 1);
        }
    };

    const handleThumbsDownClick = () => {
        setThumbsDownClicked(!thumbsDownClicked);
        setThumbsUpClicked(false);
        setThumbsDownCount(thumbsDownClicked ? 0 : 1);
    };

    return (
        <div className="card review-card">
            <div className="p-4">
                <div className='row'>
                    <div className='col-1 row-center'>
                        <img src={jeromeBellPic} alt='...' />
                    </div>
                    <div className='col-6'>
                        <span className="review-card-name">Jerome Bell</span>
                    </div>
                    <div className='col-5 row-right row-center pe-5'>
                    ⭐⭐⭐⭐⭐
                    </div>
                </div>

                <div className='row px-5 pt-4'>
                    <div className='col'>
                        <span className="review-card-reviews">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing diam donec adipiscing .</span>
                    </div>
                </div>

                <div className='px-5 pt-4'>
                    <i
                        className={`fa${thumbsUpClicked ? '-solid' : '-regular'} fa-thumbs-up pe-1`}
                        onClick={handleThumbsUpClick}
                    ></i>
                    <span className='pe-3'>{thumbsUpCount}</span>
                    <i
                        className={`fa${thumbsDownClicked ? '-solid' : '-regular'} fa-thumbs-down pe-1`}
                        onClick={handleThumbsDownClick}
                    ></i>
                    <span className=''>{thumbsDownCount}</span>
                </div>
            </div>
        </div>
    );
}

export default ReviewCard;
