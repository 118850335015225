import React, { useState } from 'react';

const IncrementDecrement = () => {
    const [quantity, setQuantity] = useState(1);

    const handleMinusClick = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    };

    const handlePlusClick = () => {
        setQuantity(quantity + 1);
    };

    return (
        <div className="quantity">
            <button className="quantity__minus" onClick={handleMinusClick}><span>-</span></button>
            <input name="quantity" type="text" className="quantity__input" value={quantity} readOnly />
            <button className="quantity__plus" onClick={handlePlusClick}><span>+</span></button>
        </div>
    );
};

export default IncrementDecrement;