import React, { useState } from 'react';
import becomeTripMasterLogo from '../../assets/images/becomeTripMaster/becomeTripMaster.png';
import companyLogo from "../../assets/images/becomeTripMaster/logo.svg"
import InputGroup from '../../components/Textbox/inputGroup';
import ShortText from '../../components/Textbox/shortText';
import ContinueButton from '../../components/Buttons/Continue/continueButton';
import { useNavigate } from 'react-router-dom';

function BecomeTripMaster(props) {

    const [currentCard, setCurrentCard] = useState(0);

    const handleContinue = () => {
        setCurrentCard((prevCard) => prevCard + 1);
    };

    const navigate = useNavigate();
    const onHome = (e) => {
        e.preventDefault();
        navigate('/');
    }
    const progressWidth = ((currentCard + 1) / 3) * 100;


    return (

        <>

            <div className="progress custom-process-trip-master">
                <div
                    className="progress-bar custom-process-bar-trip-master"
                    role="progressbar"
                    style={{ width: `${progressWidth}%` }}
                    aria-valuenow={progressWidth}
                    aria-valuemin="25"
                    aria-valuemax="100"
                ></div>
            </div>


            <div className='become-trip-master-container'>
                <img src={companyLogo} className='img-trip-master' alt='...' />




                <div style={{ display: currentCard === 0 ? 'block' : 'none' }}>
                    <div className=' pb-5'>
                        <span className=' row-center heading-1-home-white'>Become a Trip Master!</span>
                    </div>
                    <div className='trip-master-card'>

                        <div className='trip-master-logo-container'>
                            <img src={becomeTripMasterLogo} alt='..' />
                        </div>
                        <span className='trip-master-quote-container'>“An amazing travel-related quote to be added here for storytelling”</span>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 1"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 2"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <ContinueButton
                                    className="mt-5 w-100"
                                    name="Continue"
                                    onClick={handleContinue}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ display: currentCard === 1 ? 'block' : 'none' }}>
                    <div className='row-center pb-5'>
                        <span className='heading-1-home-white'>Connect your social profile</span>
                    </div>

                    <div className='trip-master-card'>
                        <div className='trip-master-logo-container'>
                            <img src={becomeTripMasterLogo} alt='..' />
                        </div>
                        <span className='trip-master-quote-container'>“An amazing travel-related quote to be added here for storytelling”</span>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 1"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 2"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <ContinueButton
                                    className="mt-5 w-100"
                                    name="Continue"
                                    onClick={handleContinue}
                                />
                            </div>
                        </div>
                    </div>

                </div>


                <div style={{ display: currentCard === 2 ? 'block' : 'none' }}>
                    <div className='pb-5'>
                        <span className='heading-1-home-white'>Just a step away - lets get you verified!</span>
                    </div>

                    <div className='trip-master-card'>
                        <div className='trip-master-logo-container'>
                            <img src={becomeTripMasterLogo} alt='..' />
                        </div>
                        <span className='trip-master-quote-container'>“An amazing travel-related quote to be added here for storytelling”</span>
                        <div className='row mt-3'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 1"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row mb-5'>
                            <div className='col-12'>
                                <InputGroup
                                    label=""
                                    control={
                                        <ShortText
                                            placeholder="Input Field 2"
                                            ShortTextClasses="style-textbox w-100"
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <ContinueButton
                                    className="mt-5 w-100"
                                    name="Continue"
                                    onClick={onHome}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BecomeTripMaster;