import React from 'react'

function InputGroup(props) {

  return (
    <>
      <div className={`c-input-group ${props.InputGroupClasses ? props.InputGroupClasses : ""}`}>
        <label className={`c-label ${props.CustomClass ? props.CustomClass : ""}`}>{props.label}</label>
        {props.control ? props.control : <div></div>}
      </div>
    </>
  )
}

export default InputGroup;