import React from 'react';

import ButtonReadMore from '../../Buttons/ReadMore/ButtonReadMore';

function RouteCardHorizontal(props) {
    return (
        <>
            <div class="card trip-route-horizontal-card p-2">


                <span className={`username-container ${props.className} profile-section-route-trip`}>
                    <div className="profile-pic-route-trip">
                        <div className="">
                            <img src={props.profilePic} alt="..." />
                        </div>
                        <div className="">
                            <span className="route-trip-profile-name">{props.profileName}</span>
                            <span className="route-trip-profile-title"><br />{props.profileTitle}</span>
                        </div>
                    </div>
                </span>
                <img src={props.backgroundImage} class="card-img-top px-2" alt="..." />



                <div class="card-body p-3">
                    <div>
                        <span className='route-trip-city'>{props.city} <span className='route-trip-country'>{props.country}</span></span>
                    </div>
                    <div className='py-2'>
                        <span className='route-trip-city-description'>{props.cityDescription}</span>
                    </div>
                    <div>
                        <ButtonReadMore
                            name="Learn More"
                            className="btn-learnMore"
                        />
                        <span className='pricing-heading ps-3'>Starting:<span className='ps-2 actual-pricing'>{props.pricing}</span></span>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default RouteCardHorizontal;
